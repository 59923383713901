exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-jsx": () => import("./../../../src/pages/aboutus.jsx" /* webpackChunkName: "component---src-pages-aboutus-jsx" */),
  "component---src-pages-case-studies-adimo-jsx": () => import("./../../../src/pages/case-studies/adimo.jsx" /* webpackChunkName: "component---src-pages-case-studies-adimo-jsx" */),
  "component---src-pages-case-studies-avon-jsx": () => import("./../../../src/pages/case-studies/avon.jsx" /* webpackChunkName: "component---src-pages-case-studies-avon-jsx" */),
  "component---src-pages-case-studies-chetwood-jsx": () => import("./../../../src/pages/case-studies/chetwood.jsx" /* webpackChunkName: "component---src-pages-case-studies-chetwood-jsx" */),
  "component---src-pages-case-studies-grocedy-jsx": () => import("./../../../src/pages/case-studies/grocedy.jsx" /* webpackChunkName: "component---src-pages-case-studies-grocedy-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-case-studies-megger-jsx": () => import("./../../../src/pages/case-studies/megger.jsx" /* webpackChunkName: "component---src-pages-case-studies-megger-jsx" */),
  "component---src-pages-case-studies-nhs-jsx": () => import("./../../../src/pages/case-studies/nhs.jsx" /* webpackChunkName: "component---src-pages-case-studies-nhs-jsx" */),
  "component---src-pages-case-studies-rainbird-jsx": () => import("./../../../src/pages/case-studies/rainbird.jsx" /* webpackChunkName: "component---src-pages-case-studies-rainbird-jsx" */),
  "component---src-pages-case-studies-roche-jsx": () => import("./../../../src/pages/case-studies/roche.jsx" /* webpackChunkName: "component---src-pages-case-studies-roche-jsx" */),
  "component---src-pages-case-studies-sct-jsx": () => import("./../../../src/pages/case-studies/sct.jsx" /* webpackChunkName: "component---src-pages-case-studies-sct-jsx" */),
  "component---src-pages-clients-jsx": () => import("./../../../src/pages/clients.jsx" /* webpackChunkName: "component---src-pages-clients-jsx" */),
  "component---src-pages-contactus-jsx": () => import("./../../../src/pages/contactus.jsx" /* webpackChunkName: "component---src-pages-contactus-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-aws-api-gateway-jsx": () => import("./../../../src/pages/partners/aws/apiGateway.jsx" /* webpackChunkName: "component---src-pages-partners-aws-api-gateway-jsx" */),
  "component---src-pages-partners-aws-cloudformation-jsx": () => import("./../../../src/pages/partners/aws/cloudformation.jsx" /* webpackChunkName: "component---src-pages-partners-aws-cloudformation-jsx" */),
  "component---src-pages-partners-aws-database-administration-jsx": () => import("./../../../src/pages/partners/aws/databaseAdministration.jsx" /* webpackChunkName: "component---src-pages-partners-aws-database-administration-jsx" */),
  "component---src-pages-partners-aws-ecs-jsx": () => import("./../../../src/pages/partners/aws/ecs.jsx" /* webpackChunkName: "component---src-pages-partners-aws-ecs-jsx" */),
  "component---src-pages-partners-aws-eks-jsx": () => import("./../../../src/pages/partners/aws/eks.jsx" /* webpackChunkName: "component---src-pages-partners-aws-eks-jsx" */),
  "component---src-pages-partners-aws-elastic-compute-cloud-jsx": () => import("./../../../src/pages/partners/aws/elasticComputeCloud.jsx" /* webpackChunkName: "component---src-pages-partners-aws-elastic-compute-cloud-jsx" */),
  "component---src-pages-partners-aws-index-jsx": () => import("./../../../src/pages/partners/aws/index.jsx" /* webpackChunkName: "component---src-pages-partners-aws-index-jsx" */),
  "component---src-pages-partners-aws-lambda-jsx": () => import("./../../../src/pages/partners/aws/lambda.jsx" /* webpackChunkName: "component---src-pages-partners-aws-lambda-jsx" */),
  "component---src-pages-partners-aws-networkandcontent-jsx": () => import("./../../../src/pages/partners/aws/networkandcontent.jsx" /* webpackChunkName: "component---src-pages-partners-aws-networkandcontent-jsx" */),
  "component---src-pages-partners-aws-rds-jsx": () => import("./../../../src/pages/partners/aws/rds.jsx" /* webpackChunkName: "component---src-pages-partners-aws-rds-jsx" */),
  "component---src-pages-partners-aws-system-manager-jsx": () => import("./../../../src/pages/partners/aws/systemManager.jsx" /* webpackChunkName: "component---src-pages-partners-aws-system-manager-jsx" */),
  "component---src-pages-partners-aws-trainingofferings-jsx": () => import("./../../../src/pages/partners/aws/trainingofferings.jsx" /* webpackChunkName: "component---src-pages-partners-aws-trainingofferings-jsx" */),
  "component---src-pages-partners-aws-waf-jsx": () => import("./../../../src/pages/partners/aws/waf.jsx" /* webpackChunkName: "component---src-pages-partners-aws-waf-jsx" */),
  "component---src-pages-partners-azure-index-jsx": () => import("./../../../src/pages/partners/azure/index.jsx" /* webpackChunkName: "component---src-pages-partners-azure-index-jsx" */),
  "component---src-pages-partners-google-cloud-index-jsx": () => import("./../../../src/pages/partners/googleCloud/index.jsx" /* webpackChunkName: "component---src-pages-partners-google-cloud-index-jsx" */),
  "component---src-pages-services-automation-jsx": () => import("./../../../src/pages/services/automation.jsx" /* webpackChunkName: "component---src-pages-services-automation-jsx" */),
  "component---src-pages-services-cloud-jsx": () => import("./../../../src/pages/services/cloud.jsx" /* webpackChunkName: "component---src-pages-services-cloud-jsx" */),
  "component---src-pages-services-containerisation-jsx": () => import("./../../../src/pages/services/containerisation.jsx" /* webpackChunkName: "component---src-pages-services-containerisation-jsx" */),
  "component---src-pages-services-digital-transformation-jsx": () => import("./../../../src/pages/services/digitalTransformation.jsx" /* webpackChunkName: "component---src-pages-services-digital-transformation-jsx" */),
  "component---src-pages-services-smb-jsx": () => import("./../../../src/pages/services/smb.jsx" /* webpackChunkName: "component---src-pages-services-smb-jsx" */),
  "component---src-pages-services-software-development-jsx": () => import("./../../../src/pages/services/softwareDevelopment.jsx" /* webpackChunkName: "component---src-pages-services-software-development-jsx" */),
  "component---src-pages-services-support-jsx": () => import("./../../../src/pages/services/support.jsx" /* webpackChunkName: "component---src-pages-services-support-jsx" */),
  "component---src-pages-services-training-jsx": () => import("./../../../src/pages/services/training.jsx" /* webpackChunkName: "component---src-pages-services-training-jsx" */),
  "component---src-pages-updates-blog-index-jsx": () => import("./../../../src/pages/updates/blog/index.jsx" /* webpackChunkName: "component---src-pages-updates-blog-index-jsx" */),
  "component---src-pages-updates-blog-template-jsx": () => import("./../../../src/pages/updates/blogTemplate.jsx" /* webpackChunkName: "component---src-pages-updates-blog-template-jsx" */),
  "component---src-pages-updates-event-template-jsx": () => import("./../../../src/pages/updates/eventTemplate.jsx" /* webpackChunkName: "component---src-pages-updates-event-template-jsx" */),
  "component---src-pages-updates-events-event-1-jsx": () => import("./../../../src/pages/updates/events/event1.jsx" /* webpackChunkName: "component---src-pages-updates-events-event-1-jsx" */),
  "component---src-pages-updates-events-index-jsx": () => import("./../../../src/pages/updates/events/index.jsx" /* webpackChunkName: "component---src-pages-updates-events-index-jsx" */)
}

